import { companies } from "./companies";

export interface Option {
  value: string;
  label: string;
  count?: number;
}

export interface Filter {
  id: "stack" | "country" | "remote" | "seniority" | "companies";
  name: string;
  options: Option[];
}

export const filters: Filter[] = [
  {
    id: "country",
    name: "Location",
    options: [
      { value: "al", label: "Albania" },
      { value: "at", label: "Austria" },
      { value: "be", label: "Belgium" },
      { value: "bg", label: "Bulgaria" },
      { value: "hr", label: "Croatia" },
      { value: "cy", label: "Cyprus" },
      { value: "cz", label: "Czech Republic" },
      { value: "ch", label: "Switzerland" },
      { value: "dk", label: "Denmark" },
      { value: "ee", label: "Estonia" },
      { value: "fi", label: "Finland" },
      { value: "fr", label: "France" },
      { value: "de", label: "Germany" },
      { value: "gr", label: "Greece" },
      { value: "hu", label: "Hungary" },
      { value: "ie", label: "Ireland" },
      { value: "it", label: "Italy" },
      { value: "lv", label: "Latvia" },
      { value: "lt", label: "Lithuania" },
      { value: "lu", label: "Luxembourg" },
      { value: "mt", label: "Malta" },
      { value: "nl", label: "Netherlands" },
      { value: "pl", label: "Poland" },
      { value: "pt", label: "Portugal" },
      { value: "ro", label: "Romania" },
      { value: "sk", label: "Slovakia" },
      { value: "si", label: "Slovenia" },
      { value: "es", label: "Spain" },
      { value: "se", label: "Sweden" },
      { value: "gb", label: "United Kingdom" },
      { value: "rs", label: "Serbia" },
    ],
  },
  {
    id: "remote",
    name: "Remote",
    options: [{ value: "true", label: "Remote" }],
  },
  {
    id: "stack",
    name: "Stack",
    options: [
      { value: "frontend", label: "Frontend" },
      { value: "backend", label: "Backend" },
      { value: "mobile", label: "Mobile" },
      { value: "sitereliability", label: "Site Reliability" },
      { value: "fullstack", label: "Fullstack" },
      { value: "dataengineering", label: "Data" },
      { value: "machinelearning", label: "Machine Learning" },
      { value: "other", label: "Other" },
    ],
  },
  {
    id: "seniority",
    name: "Seniority",
    options: [
      { value: "junior", label: "Junior" },
      { value: "mid-level", label: "Mid" },
      { value: "senior", label: "Senior" },
      { value: "staff", label: "Staff" },
      { value: "principal", label: "Principal" },
    ],
  },
  {
    id: "companies",
    name: "Company",
    options: companies.map((company) => ({
      value: company.id,
      label: company.name,
    })),
  },
];
