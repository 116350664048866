import {
  Menu,
  MenuItems,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
} from "@headlessui/react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/20/solid";
import { Form, useSearchParams, useSubmit } from "@remix-run/react";
import { Filter, filters, Option } from "~/constants/filters";
import { ActiveCount, ActiveJobFilters } from "~/types/Precompute";

const getActiveFilters = (searchParams: URLSearchParams, sectionId: string) =>
  filters
    .find((filter) => filter.id === sectionId)
    ?.options.filter((option) =>
      searchParams.getAll(sectionId).includes(option.value)
    );

interface ActiveFiltersProps {
  count: number;
  activeFilters: Record<string, Option[]>;
}

function ActiveFilters({ count, activeFilters }: ActiveFiltersProps) {
  const [, setSearchParams] = useSearchParams();

  const removeActiveFilter = (id: string, filter: Option) => {
    setSearchParams(
      (prevSearchParams) => {
        const newSearchParams = new URLSearchParams(prevSearchParams);
        // @ts-expect-error - TS doesn't know about delete value
        newSearchParams.delete(id, filter.value);
        if (!prevSearchParams.get("init")) {
          newSearchParams.set("init", "true");
        }
        return newSearchParams;
      },
      {
        preventScrollReset: true,
      }
    );
  };

  return (
    <div className="flex w-full flex-grow-0 mb-3 min-h-11 flex-wrap flex-col gap-4">
      <div className="mr-auto sm:flex sm:items-center">
        <div className="-m-1 flex flex-wrap items-center justify-center md:justify-start">
          {Object.keys(activeFilters).map((filterId) =>
            activeFilters[filterId].map((activeFilter) => (
              <button
                type="button"
                onClick={() => removeActiveFilter(filterId, activeFilter)}
                key={activeFilter.value}
                className="m-1 inline-flex items-center rounded-full border border-gray-200 bg-gray-800 py-1.5 pl-3 pr-2 text-sm font-medium text-gray-200"
              >
                <span>{activeFilter.label}</span>
                <div className="ml-1 inline-flex h-4 w-4 flex-shrink-0 rounded-full p-1 text-gray-400 hover:bg-gray-600 hover:text-gray-100">
                  <span className="sr-only">
                    Remove filter for {activeFilter.label}
                  </span>
                  <svg
                    className="h-2 w-2"
                    stroke="currentColor"
                    fill="none"
                    viewBox="0 0 8 8"
                  >
                    <path
                      strokeLinecap="round"
                      strokeWidth="1.5"
                      d="M1 1l6 6m0-6L1 7"
                    />
                  </svg>
                </div>
              </button>
            ))
          )}
        </div>
      </div>
      <div className="text-center md:text-right ml-auto w-full my-auto">
        <div className="m-0 text-gray-300">{count} jobs found</div>
      </div>
    </div>
  );
}

const getActiveJobFiltersBySectionId = (
  activeJobFilters: ActiveJobFilters | undefined,
  sectionId: string
) => {
  if (!activeJobFilters) {
    return null;
  }

  switch (sectionId) {
    case "country":
      return activeJobFilters.countryFilters;
    case "seniority":
      return activeJobFilters.seniorityFilters;
    case "stack":
      return activeJobFilters.stackFilters;
    case "companies":
      return activeJobFilters.companyFilters;
    default:
      return null;
  }
};

const useActiveJobFilters = () => {
  const [searchParams] = useSearchParams();
  const activeFilters: Record<string, Option[]> = {};
  filters.forEach((filter) => {
    const checked = searchParams.getAll(filter.id);
    if (checked.length) {
      filter.options.map((option) => {
        if (checked.includes(option.value)) {
          activeFilters[filter.id] = [
            ...(activeFilters[filter.id] || []),
            option,
          ];
        }
      });
    }
  });

  return {
    activeFilters,
  };
};

interface FilterSectionProps {
  section: Filter;
  activeJobFilters?: ActiveJobFilters;
  precomputedActiveFilters?: ActiveJobFilters;
  mobile?: boolean;
}

function FilterSection({
  section,
  mobile,
  activeJobFilters,
  precomputedActiveFilters,
}: FilterSectionProps) {
  const [searchParams] = useSearchParams();
  const checked = searchParams.getAll(section.id);

  function getActiveFilter(filtersCount: ActiveCount[]) {
    return function (option: Option) {
      const res = filtersCount?.find(
        (activeFilter) => activeFilter.value === option.value
      );

      return res;
    };
  }

  const isActive = searchParams.has(section.id);

  const activeFilters = getActiveJobFiltersBySectionId(
    activeJobFilters,
    section.id
  );

  const precomputedFilters = getActiveJobFiltersBySectionId(
    precomputedActiveFilters,
    section.id
  );

  let options = section.options;

  if (precomputedFilters) {
    options = options
      .filter(getActiveFilter(precomputedFilters))
      .map((option) => {
        let count: number | undefined;
        const activeFilter = getActiveFilter(
          activeFilters || precomputedFilters
        )(option);

        const precomputedCount = getActiveFilter(precomputedFilters)(option);

        if (activeFilter) {
          count = activeFilter.count;
        }

        const countString = count || !isActive ? ` (${count || 0})` : "";

        return {
          ...option,
          label: `${option.label} ${countString}`,
          count,
          precomputedCount: precomputedCount?.count || 0,
        };
      })
      .sort((a, b) => {
        if (a.precomputedCount && b.precomputedCount) {
          return b.precomputedCount - a.precomputedCount;
        }
        return 0;
      });
  }

  if (section.id === "companies") {
    options = options.sort((a, b) => {
      return a.label < b.label ? -1 : 1;
    });
  }

  return (
    <div className="space-y-6">
      {options.map((option, optionIdx) => (
        <div key={option.value} className="flex items-center">
          <input
            id={`filter-${mobile ? "mobile" : ""}-${section.id}-${optionIdx}`}
            name={section.id}
            value={option.value}
            checked={checked.includes(option.value)}
            onChange={(e) => {
              if (e.currentTarget.checked) {
                searchParams.append(section.id, option.value);
              } else {
                // @ts-expect-error - TS doesn't know about delete value
                searchParams.delete(section.id, option.value);
              }
            }}
            type="checkbox"
            className="h-4 w-4 rounded border-gray-300 text-lime-600 focus:ring-lime-500"
          />
          <label
            htmlFor={`filter-${mobile ? "mobile" : ""}-${
              section.id
            }-${optionIdx}`}
            className="ml-3 text-sm text-gray-100"
          >
            {option.label}
          </label>
        </div>
      ))}
    </div>
  );
}

const ArrowRight = () => {
  return (
    <div className="hidden md:flex text-lime-300 font-extralight items-center justify-center">
      <div className="">Start by selecting a filter</div>
      <div className="rotate-90">
        <ChevronUpIcon className="ml-1 h-5 w-5 flex-shrink-0 animate-bounce" />
      </div>
    </div>
  );
};

const getPosition = (index: number, total: number) => {
  if (index === 0) {
    return "left-0";
  }

  if (index === total - 1) {
    return "right-0";
  }

  return "-right-4";
};

interface FiltersProps {
  jobCount: number;
  activeJobFilters?: ActiveJobFilters;
  precomputedFilters?: ActiveJobFilters;
  alert?: React.ReactNode;
  authenticated?: boolean;
}

export function Filters({
  jobCount,
  activeJobFilters,
  precomputedFilters,
  alert,
  authenticated,
}: FiltersProps) {
  const submit = useSubmit();
  const { activeFilters } = useActiveJobFilters();

  const [searchParams] = useSearchParams();

  const onChange = (e: React.FormEvent<HTMLFormElement>) => {
    submit(e.currentTarget, {
      preventScrollReset: true,
    });
  };

  return (
    <div className="flex flex-col-reverse md:flex-col gap-6 md:gap-0">
      <div className="block md:hidden">{alert}</div>
      <ActiveFilters activeFilters={activeFilters} count={jobCount} />

      {/* Filters */}
      <section aria-labelledby="filter-heading">
        <h2 id="filter-heading" className="sr-only">
          Filters
        </h2>

        <div className="ml-auto lg:mx-0 md:pb-4 flex flex-row gap-4">
          <div className="hidden md:block">{alert}</div>
          <div className="mr-auto md:mr-0 ml-auto flex md:max-w-7xl items-center justify-end">
            {!Object.keys(activeFilters).length && !alert && (
              <div className="ml-auto mr-5">
                <ArrowRight />
              </div>
            )}
            <Menu as="div" className="relative inline-block text-left">
              <MenuItems
                transition
                className="absolute left-0 z-10 mt-2 w-40 origin-top-left rounded-md bg-gray-800 shadow-2xl ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
              ></MenuItems>
            </Menu>

            <div className="flow-root">
              <Form onChange={onChange} method="GET">
                <input type="hidden" name="init" value="true" />
                <PopoverGroup className="flex flex-wrap w-full justify-center md:w-fit gap-2 md:gap-4 items-center md:divide-x md:divide-gray-200">
                  {filters.map((section, index) => {
                    if (!authenticated && section.id === "companies") {
                      return null;
                    }

                    const activeFilters = getActiveFilters(
                      searchParams,
                      section.id
                    );
                    const activeFilterCount = activeFilters?.length || 0;
                    return (
                      <Popover
                        key={section.name}
                        className="relative inline-block px-2 md:px-4 text-left"
                      >
                        {({ open }) => (
                          <>
                            <PopoverButton className="group inline-flex justify-center text-sm font-medium text-gray-300 hover:text-gray-200">
                              <span>{section.name}</span>
                              {activeFilterCount > 0 ? (
                                <span className="ml-1.5 rounded bg-gray-700 px-1.5 py-0.5 text-xs font-semibold tabular-nums text-gray-300">
                                  {activeFilterCount}
                                </span>
                              ) : null}
                              <ChevronDownIcon
                                className="-mr-1 ml-1 h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-100"
                                aria-hidden="true"
                              />
                            </PopoverButton>

                            <PopoverPanel
                              static
                              transition
                              style={{
                                visibility: open ? "visible" : "hidden",
                              }}
                              className={`absolute ${getPosition(
                                index,
                                filters.length
                              )} right-0 max-h-96 w-fit overflow-y-auto z-10 whitespace-nowrap mt-2 origin-top-left rounded-md bg-gray-800 p-4 shadow-2xl ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in`}
                            >
                              <FilterSection
                                activeJobFilters={activeJobFilters}
                                precomputedActiveFilters={precomputedFilters}
                                section={section}
                              />
                            </PopoverPanel>
                          </>
                        )}
                      </Popover>
                    );
                  })}
                </PopoverGroup>
              </Form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
